import Vue from "vue";
import { UserSummary } from "../models/userSummary";
function getRef(orgId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("cases");
}


export async function getById(orgId, id) {
  return getRef(orgId)
    .doc(id)
    .get();
}
// export async function listByMultipleId(orgId, ids) {

//   const refs=[];

//   ids.forEach(id => {
//     refs.push(getRef(orgId).doc(id));
//   });
//   const firestore= Vue.prototype.$firestore;
//   console.log( firestore)
//   return firestore.getAll(...refs)

// }

export async function list(orgId) {
  return getRef(orgId).get();
}

export async function activeCases(orgId) {
  // console.log(orgId);
  return getRef(orgId)
  .where("isDeleted", "==", false)
  .get();
}

export async function save(orgId, payload) {
  const obj = Object.assign({}, payload);

  if (obj.id != undefined) {
    return getRef(orgId)
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef(orgId).add(obj);
  }
}

export async function search(orgId, term) {
  var searchTerm = new String(term);
  return getRef(orgId)
    .where("tags", "array-contains", searchTerm.toLowerCase())
    .where("isDeleted", "==", false)
    .get();
}

export async function savePart(orgId, caseId, payload) {
  const obj = Object.assign({}, payload);

  if (obj.id != undefined) {
    return getRef(orgId)
      .doc(caseId)
      .collection("parts")
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef(orgId)
      .doc(caseId)
      .collection("parts")
      .add(obj);
  }
}

export async function getPart(orgId, caseId, type) {
  return getRef(orgId)
    .doc(caseId)
    .collection("parts")
    .where("type", "==", type)
    .get();
}


export async function searchDeleted(orgId, term) {
  var searchTerm = new String(term);
  return getRef(orgId)
    .where("tags", "array-contains", searchTerm.toLowerCase())
    .where("isDeleted", "==", true)
    .get();
}

export async function remove(orgId, id, user) {
  const userSummary =  Object.assign({}, new UserSummary(user));
  return getRef(orgId)
    .doc(id)
    .update({ isDeleted: true, deletedBy: userSummary, deletedOn: new Date() });
}

export async function unRemove(orgId ,id) {
  return getRef(orgId)
    .doc(id)
    .update({ isDeleted: false, deletedBy: null, deletedOn: null });
}

