<template>
  <div>
    <v-btn @click="showDialog=true">add</v-btn>
    <v-dialog v-model="showDialog" width="600">
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>About me</v-card-title>

          <v-card-text>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="vm.title"
                label="Title"
                v-validate="'required'"
                :items="items"
                data-vv-as="title"
                name="title"
                :error-messages="errors.collect('title')"
              ></v-combobox>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea
                v-model="vm.description"
                label="Description"
                v-validate="'required'"
                data-vv-as="Description"
                name="description"
                :error-messages="errors.collect('description')"
              ></v-textarea>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close">Cancel</v-btn>
            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <div class="circleList">
      <ul ref="circleList">
        <li v-for="(item, i) in aboutMe.items" :key="i">
          <v-icon size="16" @click="editItem(item)">mdi-pencil</v-icon>
          <div class="title">{{item.title}}</div>
          <div class="description">{{item.description}}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import * as caseService from "../../services/caseService";
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  data() {
    return {
      showDialog: false,
      vm: {},
      editedIndex: -1,
      aboutMe: { items: [] },
      items: [
        "Important things to know",
        "Words that describe me",
        "Strengths and talents",
        "Favorite things to do and people to spend time with",
        "What I like my day to look like",
        "Things I don't like",
        "If I could be and actor/character/superhero i would be:",
        "Things that are important in my life that I want to maintain or continue.",
        "How I like to be supported"
      ]
    };
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    }
  },
  updated() {
    this.addcss();
  },
  mounted() {
    let me = this;
    caseService
      .getPart(this.currentOrganisation.id, this.currentCase.id, "about-me")
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.aboutMe = doc.data();
          me.addcss();
        });
      });
  },

  methods: {
    addcss() {
      let type = 1; //circle type - 1 whole, 0.5 half, 0.25 quarter
      let radius = "12em"; //distance from center
      let start = -90; //shift start from 0
      let elements = this.$refs.circleList.children;
      let numberOfElements = type === 1 ? elements.length : elements.length - 1; //adj for even distro of elements when not full circle
      let slice = (360 * type) / numberOfElements;

      for (let i = 0; i < elements.length; i++) {
        let e = elements[i];
        let rotate = slice * i + start;
        let rotateReverse = rotate * -1;
        e.style.cssText = `transform: rotate(${rotate}deg) translate(${radius}) rotate(${rotateReverse}deg)`;
      }
    },
    editItem(item) {
      this.editedIndex = this.aboutMe.items.indexOf(item);
      this.vm = Object.assign({}, item);
      this.showDialog = true;
    },
    deleteItem(item) {
      const index = this.aboutMe.items.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        this.aboutMe.items.splice(index, 1);
      }
    },
    close() {
      this.showDialog = false;
      setTimeout(() => {
        this.vm = {};
        this.editedIndex = -1;
      }, 300);
    },
    validateBeforeSubmit() {
      let me = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          if (me.editedIndex > -1) {
            Object.assign(me.aboutMe.items[me.editedIndex], me.vm);
          } else {
            me.aboutMe.items.push(me.vm);
          }
          me.aboutMe.type = "about-me";
          caseService
            .savePart(me.currentOrganisation.id, me.currentCase.id, me.aboutMe)
            .then(ref => {
              if (ref != undefined) {
                me.aboutMe.id = ref.id;
              }
              me.$store.dispatch("setToast", {
                message: "About me saved",
                color: "success"
              });
            })
            .catch(error => {
              console.error("Error saving about me: ", error);
            });
          this.close();
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.circleList {
  width: 100%;
  height: 1000px;
  position: absolute;
  div {
    max-width: 200px;
  }
  ul {
    width: 100%;
    height: 100%;
    position: relative;
    top: 200px;
    left: 200px; /*for example purposes only*/
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    position: absolute;
    -webkit-transition: all 2s linear;
    -moz-transition: all 2s linear;
    transition: all 2s linear;
  }
}
</style>
